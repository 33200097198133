import UseTranslation from "next-translate/useTranslation";
import React, { useState as UseState } from "react";
import { PostArrayData } from "../../../libs/api/rest";
import { LanguageUid } from "../../../libs/enums";
import { IsNullOrEmpty, OpenModelById } from "../../../libs/utils";
import ShipmentTrackingPreview from "../../views/home_page/shipment_tracking/modal";
import styles from "./styles.module.css";

var _error = {};

function MSM_ShipmentTracking({ stateAction }) {
  const { t } = UseTranslation("GetLanguageResourceResponse");

  const [shipmentTracking, setShipmentTracking] = UseState();
  const [modalIsActive, setModalIsActive] = UseState(false);
  const [code, setCode] = UseState("");
  const [search, setSearch] = UseState(false);

  const onSetCode = (e) => {
    setCode({ ...code, [e.target.name]: e.target.value.toUpperCase() });
  };

  const onGetShipmentTracking = async () => {
    if (IsNullOrEmpty(code)) return;

    setSearch(true);
    let getCode = code.value.split(",");

    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/ShipmentTracking`,
      getCode
    ).then((result) => {
      try {
        if (result[0].errorState) {
          if (result[0].errorMessage === "KAYIT YOK") {
            _error.Message = result[0].errorMessage.toUpperCase();
            setModalIsActive(false);
            setSearch(false);

            return;
          } else {
            setShipmentTracking(result);
            setModalIsActive(true);
            setSearch(false);

            _error.Message = "";
            document.body.style.overflow = "hidden";
          }
        } else {
          _error.Message = result[0].errorMessage.toUpperCase();
          setModalIsActive(false);
          setSearch(false);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div id="msm_shipment_tracking" className={styles.container}>
      <i
        role="dialog"
        aria-labelledby="kargo-takip-baslik"
        onClick={() => {
          stateAction(false);
        }}
        className="fa-solid fa-xmark"></i>

      <div className={styles.inputField}>
        <input
          onKeyUp={(event) => {
            onSetCode(event);
          }}
          type={"text"}
          name="value"
          placeholder={t(LanguageUid.SHIPMENT_TRACKING_INPUT_PLACEHOLDER)}
          aria-label="Kargo takip kodu girin"
        />

        <button
          onClick={async () => {
            await onGetShipmentTracking();
          }}>
          {!search ? (
            t(LanguageUid.SHIPMENT_TRACKING_BUTTON)
          ) : (
            <i className="fa-solid fa-spinner"></i>
          )}
        </button>

        {_error.Message && <p className={styles.errorText}>{_error.Message}</p>}
      </div>

      {modalIsActive && (
        <ShipmentTrackingPreview
          model={shipmentTracking}
          isActive={true}></ShipmentTrackingPreview>
      )}
    </div>
  );
}

export default MSM_ShipmentTracking;
