import React from "react";
import styles from "./styles.module.css";
import DeliveryTimes from "../../views/home_page/delivery_times/index";

function MSM_DeliveryTime({ stateAction }) {
  return (
    <div
      id="msm_shipment_time"
      className={styles.container}
      role="dialog"
      aria-labelledby="teslimat-zamani-baslik">
      <i
        aria-label="Kapat"
        onClick={() => {
          stateAction(false);
        }}
        className="fa-solid fa-xmark"></i>

      <div className={styles.body}>
        <DeliveryTimes />
      </div>
    </div>
  );
}

export default MSM_DeliveryTime;
