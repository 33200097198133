import UseTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React, { useState as UseState } from "react";
import { LanguageUid } from "../../../../libs/enums";
import MSM_DeliveryTime from "../../../mobile_shortcut_modals/delivery_time";
import MSM_ShipmentTracking from "../../../mobile_shortcut_modals/shipment_tracking";
import MSM_DeliveryFee from "../../../mobile_shortcut_modals/delivery_fee";
import styles from "./styles.module.css";

function MobileShortcut() {
  const { t } = UseTranslation("GetLanguageResourceResponse");

  const [shipmentTracking, setShipmentTracking] = UseState(false);
  const [shipmentFee, setShipmentFee] = UseState(false);
  const [shipmentTime, setShipmentTime] = UseState(false);

  return (
    <section id={styles.mobileShortcut}>
      <span
        onClick={() => {
          setShipmentTracking(true);
        }}
        aria-label={t(LanguageUid.SHIPMENT_TRACKING_TAB1)}>
        <i className="fa-solid fa-compass" aria-hidden="true"></i>
        <h1>{t(LanguageUid.SHIPMENT_TRACKING_TAB1)}</h1>
      </span>

      <span
        onClick={() => {
          setShipmentFee(true);
        }}
        aria-label={t(LanguageUid.SHIPMENT_TRACKING_TAB2)}>
        <i className="fa-solid fa-paper-plane" aria-hidden="true"></i>
        <h1>{t(LanguageUid.SHIPMENT_TRACKING_TAB2)}</h1>
      </span>

      <span
        onClick={() => {
          setShipmentTime(true);
        }}
        aria-label={t(LanguageUid.SHIPMENT_TRACKING_TAB3)}>
        <i className="fa-solid fa-clock" aria-hidden="true"></i>
        <h1>{t(LanguageUid.SHIPMENT_TRACKING_TAB3)}</h1>
      </span>

      <span>
        <Link href="https://enyakinptt.ptt.gov.tr/" rel="noreferrer">
          <a target={"_blank"} aria-label={t(LanguageUid.NEAREST_BRANCH_TITLE)}>
            <i className="fa-solid fa-location-dot" aria-hidden="true"></i>
            <h1>{t(LanguageUid.NEAREST_BRANCH_TITLE)}</h1>
          </a>
        </Link>
      </span>

      {shipmentTracking && (
        <MSM_ShipmentTracking stateAction={setShipmentTracking} />
      )}
      {shipmentFee && <MSM_DeliveryFee stateAction={setShipmentFee} />}
      {shipmentTime && <MSM_DeliveryTime stateAction={setShipmentTime} />}
    </section>
  );
}

export default MobileShortcut;
